<template>
  <div>
    <CRow>
      <!-- <CCol> -->
      <CCard class="startColumn-left" id="card">
        <CCardBody class="Scrollcard card-after-upload">
          <div class="Inputs">
            <h3 class="card-title">Initial inputs</h3>
            <br />

            <div class="input-container">
              <div class="InputDropdown">
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <label class="dropdown-label">Client name:</label>
                  </div>

                  <div
                    v-if="inputValues.client.id"
                    style="margin-left: auto; cursor: pointer"
                    @click="resetInputValues"
                    v-c-tooltip="{ content: 'Reset inputs' }"
                  >
                    <CIcon name="cilPencil" />
                  </div>

                  <br />
                </div>

                <DropdownWithAdd
                  :inputValues="inputValues"
                  :items="items.clients"
                  :options="{
                    ...options,
                    placeholder: 'Client',
                    inputClass: 'dropdown-input',
                  }"
                  :picked="picked"
                  :id="'client'"
                  @checkError="(v) => (hasClientNameError = v)"
                  @items-update="
                    (child) => {
                      this.items['clients'] = [...this.items.clients, child];
                      this.inputValues.client = child;
                    }
                  "
                  data-cy="dropdown-client"
                />
                <span class="smallDisclaimer error" v-if="hasClientNameError"
                  >Client name can not contain space or special characters</span
                >
                <span class="smallDisclaimer" v-else
                  >You can select existing or create new client</span
                >
              </div>

              <div v-if="inputValues.client.id" class="InputDropdown">
                <div>
                  <label class="dropdown-label">Project name:</label><br />
                </div>
                <DropdownWithAdd
                  :items="
                    items.projects.filter(
                      (a) => a.client_id == inputValues.client.id
                    )
                  "
                  :options="{
                    ...options,
                    placeholder: 'Project',
                    inputClass: 'dropdown-input',
                  }"
                  @checkError="(v) => (hasProjectNameError = v)"
                  :picked="picked"
                  :id="'project'"
                  :additional="inputValues.client.id"
                  @items-update="
                    (child) => {
                      // this.fillDropdown('projects');
                      this.items['projects'] = [...this.items.projects, child];
                      this.inputValues.project = child;
                    }
                  "
                  data-cy="dropdown-project"
                  @change-is-new="handleIsNewChange"
                  :inputValues="inputValues"
                />
                <span class="smallDisclaimer error" v-if="hasProjectNameError"
                  >Project name can not contain space or special
                  characters</span
                >
                <span v-else class="smallDisclaimer"
                  >You can select existing or create new project</span
                >
              </div>
            </div>

            <div class="reset-btn" v-if="step === 2">
              <CButton
                class="mazarsButton upload-again"
                @click="
                  () => {
                    this.step = 1;
                    this.reuseErrors = {};
                    this.leftBlocked = false;
                    this.inputValues.client = {};
                    this.inputValues.project = {};
                  }
                "
                data-cy="button-reupload"
                >Reset</CButton
              >
            </div>
          </div>
        </CCardBody>
      </CCard>
      <!-- </CCol> -->

      <!-- <CCol> -->
      <CCard
        class="startColumn-right SlowShow"
        v-if="
          leftBlocked && !(step == 2 && !reuseErrors.hasOwnProperty('errors'))
        "
      >
        <CCardBody class="Scrollcard">
          <h3 class="card-title" v-if="flow == 'new'">Create a new template</h3>
          <h3 class="card-title" v-if="flow == 'reuse'">
            Upload to existing template
          </h3>
          <br />

          <FileUpload
            v-if="step == 1 && (flow == 'new' || flow == 'reuse')"
            :return_result="true"
            :reusedTemplate="template"
            :page.sync="page"
            :headers.sync="headers"
            @get-data="getExcelData"
            @get-errors="getReuseErrors"
          />

          <div
            v-if="leftBlocked && step == 1"
            class="backText"
            @click="
              () => {
                this.leftBlocked = false;
              }
            "
          >
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8335 5.16667H2.95016L5.97516 1.53333C6.11661 1.36315 6.18466 1.14375 6.16435 0.923397C6.14403 0.703041 6.03701 0.499782 5.86683 0.358333C5.69665 0.216884 5.47725 0.148833 5.25689 0.169149C5.03654 0.189465 4.83328 0.296486 4.69183 0.466666L0.525163 5.46667C0.49713 5.50644 0.472062 5.54822 0.450163 5.59167C0.450163 5.63333 0.450163 5.65833 0.39183 5.7C0.354057 5.79555 0.33428 5.89726 0.333496 6C0.33428 6.10274 0.354057 6.20445 0.39183 6.3C0.39183 6.34167 0.391829 6.36667 0.450163 6.40833C0.472062 6.45178 0.49713 6.49356 0.525163 6.53333L4.69183 11.5333C4.77018 11.6274 4.8683 11.7031 4.9792 11.7549C5.0901 11.8068 5.21107 11.8335 5.3335 11.8333C5.52821 11.8337 5.7169 11.7659 5.86683 11.6417C5.95121 11.5717 6.02096 11.4858 6.07209 11.3888C6.12322 11.2919 6.15471 11.1858 6.16478 11.0766C6.17484 10.9675 6.16327 10.8574 6.13073 10.7528C6.0982 10.6481 6.04533 10.5509 5.97516 10.4667L2.95016 6.83333H12.8335C13.0545 6.83333 13.2665 6.74554 13.4228 6.58926C13.579 6.43298 13.6668 6.22101 13.6668 6C13.6668 5.77899 13.579 5.56702 13.4228 5.41074C13.2665 5.25446 13.0545 5.16667 12.8335 5.16667Z"
                fill="#3E6BBD"
              />
            </svg>

            Back to editing inputs
          </div>

          <div
            v-if="
              (step == 3 && flow == 'reuse') ||
              reuseErrors.hasOwnProperty('errors')
            "
          >
            <div v-if="reuseErrors.hasOwnProperty('errors')">
              <h5>Errors</h5>
              <div v-for="row in reuseErrors.errors">
                <span
                  class="badge rounded-pill"
                  style="background: red; color: white"
                  data-cy="reuse-error"
                  >ERROR</span
                >
                {{ row }}
              </div>
            </div>
            <div v-if="reuseErrors.hasOwnProperty('warnings')">
              <h5>Warnings</h5>
              <div v-for="row in reuseErrors.warnings">
                <span
                  class="badge rounded-pill"
                  style="background: gold; color: white"
                  >WARNING</span
                >
                {{ row }}
              </div>
            </div>
            <div v-if="!reuseErrors.hasOwnProperty('errors')">
              <div v-if="!reuseErrors.hasOwnProperty('warnings')">
                No warnings and errors!
              </div>
              <CButton
                class="mazarsButton"
                :disabled="buttonBlocked"
                @click="finish"
                data-cy="button-ingest"
                >Ingest data</CButton
              >
              <CButton
                class="mazarsButton"
                @click="
                  () => {
                    this.step = 1;
                    this.reuseErrors = {};
                    this.excelData = null;
                    this.findTemplate(
                      this.inputValues.client,
                      this.inputValues.project
                    );
                  }
                "
                data-cy="button-reupload"
                >Upload again</CButton
              >
            </div>
            <div v-else>
              <CButton
                class="mazarsButton"
                @click="
                  () => {
                    this.step = 1;
                    this.reuseErrors = {};
                    this.excelData = null;
                    this.findTemplate(
                      this.inputValues.client,
                      this.inputValues.project
                    );
                  }
                "
                data-cy="button-reupload"
                >Upload again</CButton
              >
            </div>
          </div>
        </CCardBody>
      </CCard>
      <CCard
        class="startColumn-right SlowShow"
        v-if="step == 2 && !reuseErrors.hasOwnProperty('errors')"
      >
        <CCardBody class="Scrollcard card-after-upload">
          <h3 class="card-title">Create a new template</h3>

          <h5 class="filename">{{ filename }}</h5>

          <h4 class="click-table-below">Check the table below ↓</h4>
          <div>
            <CButton
              class="mazarsButton upload-again"
              @click="
                () => {
                  this.step = 1;
                  this.reuseErrors = {};
                  this.excelData = null;
                  this.findTemplate(
                    this.inputValues.client,
                    this.inputValues.project
                  );
                }
              "
              data-cy="button-reupload"
              >Upload again</CButton
            >
          </div>
        </CCardBody>
      </CCard>
      <!-- </CCol> -->
    </CRow>
    <CCard
      class="SlowShow"
      v-if="step == 2 && !reuseErrors.hasOwnProperty('errors')"
      id="table"
    >
      <CCardBody class="card-after-upload">
        <div class="tableHeader">
          <div class="card-title__wrapper">
            <h3 class="card-title">Detailed data</h3>
            <div class="card-desc">*profilling is based on first 1000 rows</div>
            <br />
          </div>
        </div>
        <!-- <div v-if="invoiceTableLoading" class="spinner-border text-primary" role="status"></div> -->
        <InvoiceTable
          :fileData="flow == 'reuse' ? template.columns : excelData"
          :flow="flow"
          @upload-data="getInvoiceData"
          @onLoadDataDisable="(data) => (isLoadDataDisabled = data)"
          @render-ended="
            () => {
              this.invoiceTableLoading = false;
            }
          "
          :page="page"
          :isLoadDataDisabled="isLoadDataDisabled"
          :headers="headers"
          :custCols="template ? template.custom_columns : []"
          :fileColumns="fileColumns"
          :input-values="inputValues"
        />
      </CCardBody>
    </CCard>
    <!-- <CToaster :autohide="3000" position="top-right">
      <CToast :show.sync="isSuccessToastShow" color="success"
        >Data was sent to server!</CToast
      >
    </CToaster> -->
    <CModal
      :show.sync="isShowDisclaimer"
      :closeOnBackdrop="false"
      @blur="isShowDisclaimer = false"
      @close="isShowDisclaimer = false"
      @show="isShowDisclaimer = false"
      width="50%"
      alignment="center"
    >
      <template slot="header"><h4>Disclaimer</h4></template>
      <template>
        <p>
          {{
            `By creating a new template, you will be assigned as an owner of this dataset and responsible for its data compliance. Uploaded data will be transferred and stored in Azure cloud database located in East US and managed by Forvis Mazars Consulting LLC (Georgia). You confirm that no client agreements, NDAs, local or global regulations related to data storage, transfer or processing are violated.`
          }}
        </p>
      </template>
      <template slot="footer">
        <div class="d-flex gap-3">
          <b-button
            class="btn cus-button cuz-button--white"
            @click="isShowDisclaimer = false"
          >
            Cancel
          </b-button>
          <b-button
            class="btn cus-button"
            :disabled="isBlockBtn"
            @click="sendInvoiceData"
          >
            <div class="">
              <span>Confirm</span>
            </div>
          </b-button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import FileUpload from "../../components/FileUpload.vue";
import DropdownWithAdd from "../../components/DropdownWithAdd.vue";
import InvoiceTable from "../../components/InvoiceTable.vue";
import { ListsService } from "@/services/lists";
import { TemplatesService } from "@/services/templates";
import { CCardGroup, CRow } from "@mz/coreui-vue";
import store from "../../store";
import SwitchButton from "../../components/SwitchButton.vue";
import moment from "moment";

export default {
  name: "Start",
  components: {
    FileUpload,
    DropdownWithAdd,
    InvoiceTable,
    CCardGroup,
    CRow,
    SwitchButton,
  },
  //string for turning on the db
  //"C:\Program Files\MongoDB\Server\6.0\bin\mongod.exe" --dbpath="c:\data\db"
  data() {
    return {
      flow: "",
      step: 1,
      template: null,
      leftBlocked: false,
      excelData: null,
      customColumns: {},
      fileColumns: [],
      reuseErrors: {},
      invoiceTableLoading: true,
      isSuccessToastShow: false,
      buttonBlocked: false,
      isLoadDataDisabled: false,
      inputValues: {
        client: {},
        project: {},
      },

      items: {
        clients: ["No options"],
        projects: ["No options"],
      },
      periods: ["Annual", "Semi-Annual", "Quarterly"],
      options: {
        width: "14rem",
        outerContainerStyle: "margin: 0 auto",
        maxHeight: "200px",
        enableAdd: true,
        disabled: false,
      },

      page: "",
      headers: 1,
      hasClientNameError: false,
      hasProjectNameError: false,
      isNew: false,
      isShowDisclaimer: false,
      uploadData: null,
      isBlockBtn: false,
    };
  },

  watch: {
    inputValues: {
      handler(oldVal, newVal) {
        // if (oldVal.current != newVal.current) {
        //   this.replaceExisting = false
        // }
        this.findTemplate(this.inputValues.client, this.inputValues.project);
        if (this.inputValues.project.id && this.inputValues.client.id) {
          this.leftBlocked = true;
        }
      },
      deep: true,
    },
    leftBlocked(val) {
      this.options.disabled = val;
    },
  },

  computed: {
    // current_validator() {
    //       return this.existingPeriods ? !this.replaceExisting && !this.existingPeriods.map(a=>a.current).includes(this.inputValues.current) : true // this.inputValues.current.length
    // },
  },

  created() {
    this.fillDropdown("projects");
    this.fillDropdown("clients");
  },

  methods: {
    resetInputValues() {
      this.inputValues = {
        client: {},
        project: {},
      };
    },

    handleIsNewChange(value) {
      this.isNew = value;
    },

    picked(item, id) {
      this.inputValues[id] = { ...item };
    },

    async findTemplate(client, project) {
      var { data, res } = await TemplatesService.find({
        client_id: client.id,
        project_id: project.id,
      });
      if (res.status !== 200) {
        return alert("Unable to find a template");
      }

      if (data.success) {
        if (data.templates.length == 0) {
          this.flow = "new";
          this.template = null;
        } else {
          this.flow = "reuse";
          this.template = data.templates[0];
          var { data, res } = await ListsService.get_list({
            collection: "history_wo_user",
            filter_off: false,
          });
          if (res.status !== 200) {
            return alert("Unable to read uploads history");
          }
        }
      }
    },

    async fillDropdown(collection_name) {
      var { data, res } = await ListsService.get_list({
        collection: collection_name,
        filter_off: false,
      });
      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (data.success) {
        this.items[collection_name] = data.data;
      }
    },

    async getExcelData(child) {
      this.excelData = child.fileData
        ? child.fileData.map((a, index) => {
            let r = {
              include: true,
              fnames: a.fnames,
              last_fname: a.fnames[0],
              renamed: a.renamed,
              dtype: a.dtype,
              comment: "",
              unique: false,
            };
            if (a.dtype == "Date") {
              r.date_format = a.date_format;
            }
            return r;
          })
        : null;

      if (this.excelData) {
        this.page = child.page;
        this.headers = child.headers;
        this.fileColumns = child.columns;
        this.filename = child.filename;
        this.step = 2;
      }
    },

    async getInvoiceData(child) {
      this.uploadData = child;
      if (this.isNew) {
        this.isShowDisclaimer = true;
      } else {
        console.log("here");
        await this.sendInvoiceData();
        this.isBlockBtn = true;
      }
    },

    async sendInvoiceData() {
      console.log(this.uploadData);
      this.customColumns = this.uploadData?.custCols;
      if (this.flow === "reuse") {
        this.template.columns = this.uploadData?.newData;
        // var { data, res } = await TemplatesService.check_reuse({
        //     templateData: this.template.columns,
        //     page: this.page ? this.page : "_",
        //     headers: this.headers
        //   });
        //   if (res.status !== 200) {
        //       return alert("Error reading file, reload the page and try again");
        //   }
        //   if (data.success) {
        //       this.reuseErrors = data.data
        //       this.step = 3
        //   }
      } else {
        this.excelData.columns = this.uploadData?.newData;
      }
      this.finish(this.uploadData?.anonymized);
    },
    async getReuseErrors(child) {
      this.reuseErrors = child.errors;
      this.step = 3;
    },

    async finish(anonymized) {
      this.buttonBlocked = true;
      this.isBlockBtn = true;
      this.isLoadDataDisabled = true;
      // create client project before save
      if (!this.inputValues.project || !this.inputValues.project.name) {
        return alert("Empty project name");
      }
      if (!this.inputValues.client || !this.inputValues.client.name) {
        return alert("Empty client name");
      }
      const { data: project, res: projectRes } = await ListsService.record_item(
        {
          collection: "projects",
          recordData: this.inputValues.project,
        }
      );
      if (projectRes.status !== 200 || !project.success) {
        return alert("Error adding item to collection");
      }
      if (!project.success) {
        return alert("Already exists and access is restricted");
      }
      var newEntry = {
        client: this.inputValues.client.id,
        project: project.id,
        client_name: this.inputValues.client.name,
        project_name: this.inputValues.project.name,
        columns: JSON.parse(JSON.stringify(this.excelData)),
      };
      if (this.customColumns != [["", ""]]) {
        newEntry.custom_columns = this.customColumns;
      }

      let mode = this.flow;

      //record template
      // let mode;
      // if (this.flow === "new") {
      //   mode = "new";
      //   newEntry.columns = newEntry.columns.filter((a) => a.include);
      //   var { data, res } = await TemplatesService.record({
      //     recordData: { ...newEntry, datetime: moment.utc() },
      //     mode: mode,
      //   });
      //   if (res.status !== 200) {
      //     return alert("Please check your internet connetion or log in");
      //   }
      //   if (data.success) {
      //     newEntry.id = data.id;
      //   }
      // } else {
      //   mode = "update";
      //   this.template.custom_columns = this.customColumns;
      //   var { data, res } = await TemplatesService.record({
      //     recordData: { ...this.template, datetime: moment.utc() },
      //     mode: mode,
      //   });
      //   if (res.status !== 200) {
      //     return alert("Please check your internet connetion or log in");
      //   }
      //   if (data.success) {
      //     newEntry.id = data.id;
      //   }
      // }

      //record history item

      let historyEntry = {
        client: this.inputValues.client.name,
        project: this.inputValues.project.name,
        filename: this.filename,
        custom: this.customColumns,
        status: "in_progress",
        datetime: moment.utc(),
      };
      const { data: history, res: historyRes } = await ListsService.record_item(
        {
          collection: "history",
          recordData: historyEntry,
        }
      );
      if (historyRes.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (history.id) {
        var templ_id = history.id;
      }
      this.buttonBlocked = false;
      this.isBlockBtn = false;
      //recording to SQL

      this.step = 1;
      this.leftBlocked = false;
      this.reuseErrors = {};
      this.invoiceTableLoading = true;

      var templateForProcessing =
        this.flow === "new"
          ? { ...newEntry }
          : { ...this.template, custom_columns: this.customColumns };
      templateForProcessing.table_name =
        this.inputValues.client.name + "-" + this.inputValues.project.name;

      this.flow = "";
      this.inputValues = { client: {}, project: {} };
      this.excelData = null;
      this.template = null;

      var ftype = store.state.uploadedFileType;

      try {
        var { data, res } = await TemplatesService.to_sql({
          templateData: templateForProcessing,
          page: this.page ? this.page : "_",
          headers: this.headers,
          ftype: ftype,
          history_entry_id: templ_id ? templ_id : "_",
          anonymized,
          mode,
        });
        this.$toasted.show("Data was sent to server!", {
          position: "bottom-right",
          duration: 5000,
        });
        console.log("pushing /");
        this.isLoadDataDisabled = false;
        this.isShowDisclaimer = false;

        // window.location.pathname = "/";
        this.$router.push("/");
      } catch (err) {
        console.error(err);
        this.$toasted.error("Error, access to template is restricted", {
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
}
.reset-btn {
  display: flex;
  justify-content: flex-end;
}
.periodRange {
  width: 15rem;
}
.card-title__wrapper {
  display: flex;
  align-items: center;
}
.card-desc {
  margin-bottom: 0.3rem;
  margin-left: 16px;
  color: #b3b6ba;
  font-size: 12px;
  line-height: 14px;
}
.filename {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.card-after-upload {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.upload-again {
  margin: 0px;
}
.click-table-below {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.ScrollCard {
  display: flex;
  padding: 30px;
}
.Inputs {
  width: 100%;
}
.InputDropdown {
  // width: 30rem;
}
label {
  font-size: 18px;
  font-weight: 600;
}
.smallDisclaimer {
  font-size: 10px;
  padding-top: -20px;

  &.error {
    color: #dc3545;
  }
}
.mazarsButton {
  align-items: center;
  background: linear-gradient(95.41deg, #254786 -5.88%, #389cd9 125.73%);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  max-width: 100%;
  min-height: 42px;
  overflow: hidden;
  padding: 12px 30px;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
}

.mazarsButton:hover {
  background: linear-gradient(95.41deg, #254786 20.88%, #389cd9 130.73%);
  color: #fff;
}
.mr0 {
  margin-right: 0;
}
.backText {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-top: 40px;
  margin-left: 15px;
  color: #3e6bbd;
  display: inline-block;

  .c-icon {
    width: 20px;
    height: 20px;
  }
}
CBadge {
  background-color: gold;
}
.startColumn-left {
  width: 47.5%;
  margin-right: 1%;
  margin-left: 1.5%;
}
.startColumn-right {
  width: 47.5%;
  margin-left: 1%;
  margin-right: 1.5%;
}
.reuseNote {
  font-weight: 500;
  white-space: pre-line;
}
.existingPeriodBadge {
  color: #46586c;
  background-color: #98afc7;
  border: solid #46586c;
  margin: 2px 5px;
  font-size: 0.7rem;
}
.belowArrow {
  margin-top: 10rem;
  margin-left: 7rem;
}
.tableHeader {
  display: flex;
  justify-content: space-between;
}
.rightHeaderButton {
  /* margin-left: 50%; */
  margin-top: -20px;
}
.SlowShow {
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
</style>
