var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCard',{staticClass:"startColumn-left",attrs:{"id":"card"}},[_c('CCardBody',{staticClass:"Scrollcard card-after-upload"},[_c('div',{staticClass:"Inputs"},[_c('h3',{staticClass:"card-title"},[_vm._v("Initial inputs")]),_c('br'),_c('div',{staticClass:"input-container"},[_c('div',{staticClass:"InputDropdown"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('label',{staticClass:"dropdown-label"},[_vm._v("Client name:")])]),(_vm.inputValues.client.id)?_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Reset inputs' }),expression:"{ content: 'Reset inputs' }"}],staticStyle:{"margin-left":"auto","cursor":"pointer"},on:{"click":_vm.resetInputValues}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1):_vm._e(),_c('br')]),_c('DropdownWithAdd',{attrs:{"inputValues":_vm.inputValues,"items":_vm.items.clients,"options":{
                  ..._vm.options,
                  placeholder: 'Client',
                  inputClass: 'dropdown-input',
                },"picked":_vm.picked,"id":'client',"data-cy":"dropdown-client"},on:{"checkError":(v) => (_vm.hasClientNameError = v),"items-update":(child) => {
                    this.items['clients'] = [...this.items.clients, child];
                    this.inputValues.client = child;
                  }}}),(_vm.hasClientNameError)?_c('span',{staticClass:"smallDisclaimer error"},[_vm._v("Client name can not contain space or special characters")]):_c('span',{staticClass:"smallDisclaimer"},[_vm._v("You can select existing or create new client")])],1),(_vm.inputValues.client.id)?_c('div',{staticClass:"InputDropdown"},[_c('div',[_c('label',{staticClass:"dropdown-label"},[_vm._v("Project name:")]),_c('br')]),_c('DropdownWithAdd',{attrs:{"items":_vm.items.projects.filter(
                    (a) => a.client_id == _vm.inputValues.client.id
                  ),"options":{
                  ..._vm.options,
                  placeholder: 'Project',
                  inputClass: 'dropdown-input',
                },"picked":_vm.picked,"id":'project',"additional":_vm.inputValues.client.id,"data-cy":"dropdown-project","inputValues":_vm.inputValues},on:{"checkError":(v) => (_vm.hasProjectNameError = v),"items-update":(child) => {
                    // this.fillDropdown('projects');
                    this.items['projects'] = [...this.items.projects, child];
                    this.inputValues.project = child;
                  },"change-is-new":_vm.handleIsNewChange}}),(_vm.hasProjectNameError)?_c('span',{staticClass:"smallDisclaimer error"},[_vm._v("Project name can not contain space or special characters")]):_c('span',{staticClass:"smallDisclaimer"},[_vm._v("You can select existing or create new project")])],1):_vm._e()]),(_vm.step === 2)?_c('div',{staticClass:"reset-btn"},[_c('CButton',{staticClass:"mazarsButton upload-again",attrs:{"data-cy":"button-reupload"},on:{"click":() => {
                  this.step = 1;
                  this.reuseErrors = {};
                  this.leftBlocked = false;
                  this.inputValues.client = {};
                  this.inputValues.project = {};
                }}},[_vm._v("Reset")])],1):_vm._e()])])],1),(
        _vm.leftBlocked && !(_vm.step == 2 && !_vm.reuseErrors.hasOwnProperty('errors'))
      )?_c('CCard',{staticClass:"startColumn-right SlowShow"},[_c('CCardBody',{staticClass:"Scrollcard"},[(_vm.flow == 'new')?_c('h3',{staticClass:"card-title"},[_vm._v("Create a new template")]):_vm._e(),(_vm.flow == 'reuse')?_c('h3',{staticClass:"card-title"},[_vm._v(" Upload to existing template ")]):_vm._e(),_c('br'),(_vm.step == 1 && (_vm.flow == 'new' || _vm.flow == 'reuse'))?_c('FileUpload',{attrs:{"return_result":true,"reusedTemplate":_vm.template,"page":_vm.page,"headers":_vm.headers},on:{"update:page":function($event){_vm.page=$event},"update:headers":function($event){_vm.headers=$event},"get-data":_vm.getExcelData,"get-errors":_vm.getReuseErrors}}):_vm._e(),(_vm.leftBlocked && _vm.step == 1)?_c('div',{staticClass:"backText",on:{"click":() => {
              this.leftBlocked = false;
            }}},[_c('svg',{attrs:{"width":"14","height":"12","viewBox":"0 0 14 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.8335 5.16667H2.95016L5.97516 1.53333C6.11661 1.36315 6.18466 1.14375 6.16435 0.923397C6.14403 0.703041 6.03701 0.499782 5.86683 0.358333C5.69665 0.216884 5.47725 0.148833 5.25689 0.169149C5.03654 0.189465 4.83328 0.296486 4.69183 0.466666L0.525163 5.46667C0.49713 5.50644 0.472062 5.54822 0.450163 5.59167C0.450163 5.63333 0.450163 5.65833 0.39183 5.7C0.354057 5.79555 0.33428 5.89726 0.333496 6C0.33428 6.10274 0.354057 6.20445 0.39183 6.3C0.39183 6.34167 0.391829 6.36667 0.450163 6.40833C0.472062 6.45178 0.49713 6.49356 0.525163 6.53333L4.69183 11.5333C4.77018 11.6274 4.8683 11.7031 4.9792 11.7549C5.0901 11.8068 5.21107 11.8335 5.3335 11.8333C5.52821 11.8337 5.7169 11.7659 5.86683 11.6417C5.95121 11.5717 6.02096 11.4858 6.07209 11.3888C6.12322 11.2919 6.15471 11.1858 6.16478 11.0766C6.17484 10.9675 6.16327 10.8574 6.13073 10.7528C6.0982 10.6481 6.04533 10.5509 5.97516 10.4667L2.95016 6.83333H12.8335C13.0545 6.83333 13.2665 6.74554 13.4228 6.58926C13.579 6.43298 13.6668 6.22101 13.6668 6C13.6668 5.77899 13.579 5.56702 13.4228 5.41074C13.2665 5.25446 13.0545 5.16667 12.8335 5.16667Z","fill":"#3E6BBD"}})]),_vm._v(" Back to editing inputs ")]):_vm._e(),(
            (_vm.step == 3 && _vm.flow == 'reuse') ||
            _vm.reuseErrors.hasOwnProperty('errors')
          )?_c('div',[(_vm.reuseErrors.hasOwnProperty('errors'))?_c('div',[_c('h5',[_vm._v("Errors")]),_vm._l((_vm.reuseErrors.errors),function(row){return _c('div',[_c('span',{staticClass:"badge rounded-pill",staticStyle:{"background":"red","color":"white"},attrs:{"data-cy":"reuse-error"}},[_vm._v("ERROR")]),_vm._v(" "+_vm._s(row)+" ")])})],2):_vm._e(),(_vm.reuseErrors.hasOwnProperty('warnings'))?_c('div',[_c('h5',[_vm._v("Warnings")]),_vm._l((_vm.reuseErrors.warnings),function(row){return _c('div',[_c('span',{staticClass:"badge rounded-pill",staticStyle:{"background":"gold","color":"white"}},[_vm._v("WARNING")]),_vm._v(" "+_vm._s(row)+" ")])})],2):_vm._e(),(!_vm.reuseErrors.hasOwnProperty('errors'))?_c('div',[(!_vm.reuseErrors.hasOwnProperty('warnings'))?_c('div',[_vm._v(" No warnings and errors! ")]):_vm._e(),_c('CButton',{staticClass:"mazarsButton",attrs:{"disabled":_vm.buttonBlocked,"data-cy":"button-ingest"},on:{"click":_vm.finish}},[_vm._v("Ingest data")]),_c('CButton',{staticClass:"mazarsButton",attrs:{"data-cy":"button-reupload"},on:{"click":() => {
                  this.step = 1;
                  this.reuseErrors = {};
                  this.excelData = null;
                  this.findTemplate(
                    this.inputValues.client,
                    this.inputValues.project
                  );
                }}},[_vm._v("Upload again")])],1):_c('div',[_c('CButton',{staticClass:"mazarsButton",attrs:{"data-cy":"button-reupload"},on:{"click":() => {
                  this.step = 1;
                  this.reuseErrors = {};
                  this.excelData = null;
                  this.findTemplate(
                    this.inputValues.client,
                    this.inputValues.project
                  );
                }}},[_vm._v("Upload again")])],1)]):_vm._e()],1)],1):_vm._e(),(_vm.step == 2 && !_vm.reuseErrors.hasOwnProperty('errors'))?_c('CCard',{staticClass:"startColumn-right SlowShow"},[_c('CCardBody',{staticClass:"Scrollcard card-after-upload"},[_c('h3',{staticClass:"card-title"},[_vm._v("Create a new template")]),_c('h5',{staticClass:"filename"},[_vm._v(_vm._s(_vm.filename))]),_c('h4',{staticClass:"click-table-below"},[_vm._v("Check the table below ↓")]),_c('div',[_c('CButton',{staticClass:"mazarsButton upload-again",attrs:{"data-cy":"button-reupload"},on:{"click":() => {
                this.step = 1;
                this.reuseErrors = {};
                this.excelData = null;
                this.findTemplate(
                  this.inputValues.client,
                  this.inputValues.project
                );
              }}},[_vm._v("Upload again")])],1)])],1):_vm._e()],1),(_vm.step == 2 && !_vm.reuseErrors.hasOwnProperty('errors'))?_c('CCard',{staticClass:"SlowShow",attrs:{"id":"table"}},[_c('CCardBody',{staticClass:"card-after-upload"},[_c('div',{staticClass:"tableHeader"},[_c('div',{staticClass:"card-title__wrapper"},[_c('h3',{staticClass:"card-title"},[_vm._v("Detailed data")]),_c('div',{staticClass:"card-desc"},[_vm._v("*profilling is based on first 1000 rows")]),_c('br')])]),_c('InvoiceTable',{attrs:{"fileData":_vm.flow == 'reuse' ? _vm.template.columns : _vm.excelData,"flow":_vm.flow,"page":_vm.page,"isLoadDataDisabled":_vm.isLoadDataDisabled,"headers":_vm.headers,"custCols":_vm.template ? _vm.template.custom_columns : [],"fileColumns":_vm.fileColumns,"input-values":_vm.inputValues},on:{"upload-data":_vm.getInvoiceData,"onLoadDataDisable":(data) => (_vm.isLoadDataDisabled = data),"render-ended":() => {
            this.invoiceTableLoading = false;
          }}})],1)],1):_vm._e(),_c('CModal',{attrs:{"show":_vm.isShowDisclaimer,"closeOnBackdrop":false,"width":"50%","alignment":"center"},on:{"update:show":function($event){_vm.isShowDisclaimer=$event},"blur":function($event){_vm.isShowDisclaimer = false},"close":function($event){_vm.isShowDisclaimer = false},"show":function($event){_vm.isShowDisclaimer = false}}},[_c('template',{slot:"header"},[_c('h4',[_vm._v("Disclaimer")])]),[_c('p',[_vm._v(" "+_vm._s(`By creating a new template, you will be assigned as an owner of this dataset and responsible for its data compliance. Uploaded data will be transferred and stored in Azure cloud database located in East US and managed by Forvis Mazars Consulting LLC (Georgia). You confirm that no client agreements, NDAs, local or global regulations related to data storage, transfer or processing are violated.`)+" ")])],_c('template',{slot:"footer"},[_c('div',{staticClass:"d-flex gap-3"},[_c('b-button',{staticClass:"btn cus-button cuz-button--white",on:{"click":function($event){_vm.isShowDisclaimer = false}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"btn cus-button",attrs:{"disabled":_vm.isBlockBtn},on:{"click":_vm.sendInvoiceData}},[_c('div',{},[_c('span',[_vm._v("Confirm")])])])],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }