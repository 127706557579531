var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closePicker),expression:"closePicker"}],class:[_vm.outerContainerClass],style:(_vm.outerContainerStyle)},[_c('div',{staticClass:"drp-content"},[_c('div',{staticStyle:{"width":"100%"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedItem.name),expression:"selectedItem.name"}],class:[_vm.inputClass, _vm.hasNameError ? 'error' : ''],style:([
          { height: _vm.options['height'], width: '100%' },
          _vm.options['inputStyle'],
        ]),attrs:{"autocomplete":_vm.options['autocomplete'],"placeholder":_vm.options['placeholder'] || '',"readonly":_vm.options['readonly'],"disabled":_vm.options['disabled'] || (_vm.id == 'client' && _vm.inputValues.client.id)},domProps:{"value":(_vm.selectedItem.name)},on:{"blur":_vm.inputBlurCheck,"click":_vm.inputClicked,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedItem, "name", $event.target.value)}}})]),(_vm.showAddButton)?_c('b-button',{staticClass:"btn mazarsButton-sm cus-button p-2",attrs:{"disabled":_vm.isLoading,"data-cy":"button-add-db"},on:{"click":_vm.recordListValue}},[_c('div',{staticClass:"btn-loader__wrapper",class:{ 'one-col': !_vm.isLoading }},[_c('span',[_vm._v("Create")]),(_vm.isLoading)?_c('CSpinner',{staticStyle:{"width":"1.5rem","height":"1.5rem"},attrs:{"color":"white"}}):_vm._e()],1)]):_vm._e(),(_vm.client_exists)?_c('div',{staticClass:"ClientExists"},[_vm._v("Client already exists")]):_vm._e()],1),_c('transition',{attrs:{"name":_vm.transitionName}},[(_vm.isOpen)?_c('div',{class:[_vm.dropdownClass],style:([
        {
          top: _vm.y,
          left: _vm.x,
          width: _vm.options['width'],
          'margin-top': '40px', // options['height'],
          'overflow-y': _vm.maxHeightExists,
          'max-height': _vm.options['maxHeight'] || 'auto',
        },
        _vm.options['dropdownStyle'],
      ])},_vm._l((_vm.filteredItemList),function(item,index){return _c('div',{key:index,class:[_vm.itemContainerClass],style:(_vm.options['itemContainerStyle']),on:{"click":function($event){return _vm.itemPicked(item)}}},[(_vm.isSelected(item))?_c('p',{class:[_vm.activeItemClass],style:([{ width: _vm.options['width'] }, _vm.options['activeItemStyle']])},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('p',{class:[_vm.nonActiveItemClass],style:([
            { width: _vm.options['width'] },
            _vm.options['nonActiveItemStyle'],
          ])},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }