<template>
  <div
    v-on-clickaway="closePicker"
    :class="[outerContainerClass]"
    :style="outerContainerStyle"
  >
    <div class="drp-content">
      <div style="width: 100%">
        <input
          :style="[
            { height: options['height'], width: '100%' },
            options['inputStyle'],
          ]"
          :class="[inputClass, hasNameError ? 'error' : '']"
          :autocomplete="options['autocomplete']"
          @blur="inputBlurCheck"
          @click="inputClicked"
          v-model="selectedItem.name"
          :placeholder="options['placeholder'] || ''"
          :readonly="options['readonly']"
          :disabled="
            options['disabled'] || (id == 'client' && inputValues.client.id)
          "
        />
      </div>

      <b-button
        v-if="showAddButton"
        class="btn mazarsButton-sm cus-button p-2"
        @click="recordListValue"
        :disabled="isLoading"
        data-cy="button-add-db"
      >
        <div class="btn-loader__wrapper" :class="{ 'one-col': !isLoading }">
          <span>Create</span>

          <CSpinner
            v-if="isLoading"
            color="white"
            style="width: 1.5rem; height: 1.5rem"
          />
        </div>
      </b-button>

      <div class="ClientExists" v-if="client_exists">Client already exists</div>
    </div>
    <transition :name="transitionName">
      <div
        v-if="isOpen"
        :style="[
          {
            top: y,
            left: x,
            width: options['width'],
            'margin-top': '40px', // options['height'],
            'overflow-y': maxHeightExists,
            'max-height': options['maxHeight'] || 'auto',
          },
          options['dropdownStyle'],
        ]"
        :class="[dropdownClass]"
      >
        <div
          v-for="(item, index) in filteredItemList"
          :key="index"
          :style="options['itemContainerStyle']"
          :class="[itemContainerClass]"
          @click="itemPicked(item)"
        >
          <!-- necessary to do v-if for dynamic active class and styles -->
          <p
            v-if="isSelected(item)"
            :class="[activeItemClass]"
            :style="[{ width: options['width'] }, options['activeItemStyle']]"
          >
            {{ item.name }}
          </p>
          <p
            v-else
            :class="[nonActiveItemClass]"
            :style="[
              { width: options['width'] },
              options['nonActiveItemStyle'],
            ]"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//options reference:
//https://github.com/cgberlin/vue-input-dropdown
import { ListsService } from "@/services/lists";
import { TemplatesService } from "../services/templates";

import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "DropdownWithAdd",
  mixins: [clickaway],
  props: ["options", "items", "picked", "id", "additional", "inputValues"],
  created() {
    this.itemList = this.items;
  },
  data() {
    return {
      isOpen: false,
      x: 0,
      y: 0,
      selectedItem: { name: "" },
      itemList: [],
      client_exists: false,
      isLoading: false,
    };
  },
  watch: {
    items: {
      handler: function (to, from) {
        this.itemList = to;
      },
      deep: true,
    },
    selectedItem: {
      handler: async function (to, from) {
        if (this.id == "client") {
          var { data, res } = await ListsService.get_list({
            collection: "clients",
            filter_off: true,
          });
          if (res.status !== 200) {
            return alert("Please check your internet connetion or log in");
          }
          if (data.success) {
            this.client_exists =
              data.data.includes(to) && !this.items.includes(to);
          }
        }
        let { items } = this;
        if (to) this.itemList = items;
      },
    },
    // 'selectedItem.name': {
    //     handler(newValue, oldValue) {
    //         console.log('New value:', newValue);
    //         console.log('Old value:', oldValue);
    //         this.itemList = this.items
    //     },
    //     immediate: true,
    // },
  },
  computed: {
    filteredItemList() {
      if (this.selectedItem && this.selectedItem.name !== "") {
        return this.itemList.filter((item) =>
          item.name
            ?.toLowerCase()
            .includes(this.selectedItem.name?.toLocaleLowerCase())
        );
      } else {
        return this.itemList;
      }
    },
    showAddButton() {
      return (
        this.options.enableAdd &&
        !this.items.map((a) => a.name).includes(this.selectedItem.name) &&
        this.selectedItem.name &&
        !this.client_exists &&
        this.checkSpecialCharacters
      );
    },
    hasNameError() {
      if (!this.selectedItem.name) {
        this.$emit("checkError", false);
        return false;
      }
      const check = !this.checkSpecialCharacters;
      this.$emit("checkError", check);
      return check;
    },
    checkSpecialCharacters() {
      return /^[a-zA-Z0-9]([a-zA-Z0-9_]*[a-zA-Z0-9])?$/.test(
        this.selectedItem.name
      );
    },
    outerContainerStyle() {
      let { options } = this;
      if (options && options["outerContainerStyle"])
        return options["outerContainerStyle"];
      else return "";
    },
    outerContainerClass() {
      let { options } = this;
      if (options && options["outerContainerClass"])
        return options["outerContainerClass"];
      else return "dropdown-container";
    },
    inputClass() {
      let { options } = this;
      if (options && options["inputClass"]) return options["inputClass"];
      else return "input-box";
    },
    dropdownClass() {
      let { options } = this;
      if (options && options["dropdownClass"]) return options["dropdownClass"];
      else return "dropdown-inner";
    },
    itemContainerClass() {
      let { options } = this;
      if (options && options["itemContainerClass"])
        return options["itemContainerClass"];
      else return "item";
    },
    activeClass() {
      let { options } = this;
      if (options && options["activeClass"]) return options["activeClass"];
      else return "d_active";
    },
    activeItemClass() {
      let { options } = this;
      if (options && options["activeItemClass"])
        return options["activeItemClass"];
      else return "d_active";
    },
    nonActiveItemClass() {
      let { options } = this;
      if (options && options["nonActiveItemClass"])
        return options["nonActiveItemClass"];
      else return "d_non-active";
    },
    transitionName() {
      let { options } = this;
      if (options && options["transitionName"])
        return options["transitionName"];
      else return "slide-fade";
    },
    maxHeightExists() {
      let { options } = this;
      if (options && options["maxHeight"]) return "auto";
    },
  },
  methods: {
    async recordListValue() {
      if (this.id === "project") {
        const { data: proData, res: proRes } =
          await TemplatesService.find_project({
            client_id: this.additional,
            project: this.selectedItem.name,
          });
        if (!proData.success) {
          return alert("Already exists and access is restricted");
        }
        this.selectedItem.client_id = this.additional;
        this.isOpen = false;
        this.$emit("items-update", { id: "tempId", ...this.selectedItem });
        this.$emit("change-is-new", true);
        return;
      }
      this.isLoading = true;

      var { data, res } = await ListsService.record_item({
        collection: this.id + "s",
        recordData: this.selectedItem,
      });
      if (res.status !== 200) {
        return alert("Error adding item to collection");
      }
      if (data.success) {
        this.isOpen = false;
        this.isLoading = false;
        this.$emit("items-update", { id: data.id, ...this.selectedItem });
        this.$emit("change-is-new", true);
      } else {
        this.isLoading = false;
        return alert("Already exists and access is restricted");
      }
    },

    inputClicked(e) {
      let { isOpen, items } = this;
      if (!isOpen) {
        this.isOpen = true;
        let { x, y } = this.getPos(e.target);
        this.x = x.toString() + "px";
        this.y = y.toString() + "px";
        this.itemList = items;
      }
    },
    itemPicked(item) {
      this.isOpen = false;
      this.selectedItem.name = item.name;
      this.picked(item, this.id);
    },
    closePicker() {
      this.isOpen = false;
    },
    isSelected(item) {
      let { selectedItem } = this;
      if (item === selectedItem) return true;
      else return false;
    },
    inputBlurCheck() {
      let { itemList, selectedItem, options, items } = this;
      let len = items.length;
      if (selectedItem.name === "") {
        this.picked({}, this.id);
        this.itemList = items;
        return;
      }
      if (len <= 0 || options["forceSelect"]) return;
      if (selectedItem) itemList = items;
      if (itemList.length <= 0) {
        this.picked(selectedItem, this.id);
      }
    },
    getPos(el) {
      let rect = el.getBoundingClientRect();
      return { x: rect.left + window.x, y: rect.top + window.y };
    },
  },
};
</script>

<style>
.drp-content {
  display: flex;
  gap: 10px;
}
.dropdown-container,
.input-box,
.item,
.d_active,
.d_non-active {
  height: 100%;
}
input:focus {
  outline: none;
}
.dropdown-container,
.dropdown-inner {
  display: flex;
  flex-direction: column;
}
.dropdown-container {
  padding-bottom: 1rem;
}
.dropdown-inner {
  width: 2rem;
  height: auto;
  position: absolute;
  z-index: 999999;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
}
.dropdown-inner::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dropdown-inner::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background-color: gray;
}
.input-box {
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}
.item {
  height: auto;
}
.item:hover {
  cursor: pointer;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
}
.d_active,
.d_non-active {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  margin: 0;
}
.d_active {
  background-color: lightgray;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.mazarsButton-sm {
  align-items: center;
  background: linear-gradient(95.41deg, #254786 -5.88%, #389cd9 125.73%);
  border-radius: 10px;
  /* border-bottom-left-radius: 1.75rem; */
  color: #fff;
  height: 41px;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.7rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-height: 1.7rem;
  width: 7rem;
  overflow: hidden;
  padding: 0.6rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
  /* margin-left: 20px; */
  transition: all 400ms;
}

.mazarsButton-sm:hover {
  background: linear-gradient(95.41deg, #254786 20.88%, #389cd9 130.73%);
  color: #fff;
}
.ClientExists {
  color: gray;
  margin-top: 5px;
  position: relative;
  display: inline-flex;
  margin-left: 10px;
}
</style>
