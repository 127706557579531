import { request } from "@/utils/request";
import { BASE_URL } from '@/constants/config';
import { ActiveUser } from "@/services/user";
import store from '../store'

export const EXCEL_ENDPOINTS = {
  upload: `${BASE_URL}api/excel/upload`,
  extract: `${BASE_URL}api/excel/extract`,
};

class Excel {

  updateProgress(evt)
  { 
    if (evt.loaded) {
      window.dispatchEvent(new CustomEvent('progress-changed', {
        detail: {
          loaded: evt.loaded,
          total: evt.total
        }
      }));
    }
  }

  async extract({ headers, page, file_type }) {
    var {data, res} = await request({
      url: EXCEL_ENDPOINTS.extract,
      method: "POST",
      skipRedirect: true,
      body: {
        headers,
        page,
        file_type
      },
    });
    return {
      data,
      res,
    };
  }

  async upload(file) {
    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    var onProgress = null
    var fileId = 1
    var result = {
      currentStatus: STATUS_INITIAL,
      uploadedFiles: [],
      fileData: null
    }

    return new Promise((resolve, reject) => {
      const uploadedFilename = file.name;
      const fileName = uploadedFilename.split('.')
      const ftype = fileName[fileName.length - 1]
      store.commit('set', ['uploadedFileType', ftype])
      const formData = new FormData();
      // Files bigger then 80MB aren't supported with current nginx config
      if (file.size > 1000000000) {
        reject('File is too big');
      }
      formData.append('file', file, uploadedFilename);
      formData.append('file_id', fileId);
      // formData.append('page', page);
      // formData.append('headers', headers);

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          onProgress && onProgress(100);
          if (xhr.status === 200) {
            result.currentStatus = STATUS_SUCCESS;
            result.uploadedFiles = [].concat(file);

            result.filename = JSON.parse(xhr.response).filename
            result.file_type = JSON.parse(xhr.response).file_type
            result.pages = JSON.parse(xhr.response).pages
            resolve(result);
            // console.log(JSON.parse(xhr.response))

          } else {
            if (xhr.responseText == '{"detail":"file_error"}') {
              confirm("Error reading file. Check that page and line exist in file and upload again.")
            }
            try {
              const response = JSON.parse(xhr.responseText);
              result.currentStatus = STATUS_FAILED;
              resolve(result);
              // reject(`Error occurred during upload. ${response?.detail?.message}.`);
            } catch {
              result.currentStatus = STATUS_FAILED;
              resolve(result);
            }
          }
        }
      };

      xhr.onprogress = function (e) {
        console.log(e.loaded)
        if (e.lengthComputable) {
          onProgress && onProgress(e.loaded / e.total * 100);
        }
      };
      onProgress && onProgress(0);

      xhr.upload.addEventListener("progress", this.updateProgress, false);
      xhr.addEventListener("readystatechange", this.updateProgress, false);

      xhr.open('post', EXCEL_ENDPOINTS.upload);
      xhr.setRequestHeader("authorization", ActiveUser.getAuthorization());
      xhr.send(formData);
    });
  }
}

export const ExcelService = new Excel();