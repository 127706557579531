<template>
  <div class="login-right">
    <form
      enctype="multipart/form-data"
      novalidate
      v-if="isInitial || isSaving || isExtract"
    >
      <!-- <h1>Upload file</h1> -->
      <div class="dropbox">
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="
            filesChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
          data-cy="upload-file"
          accept=".xlsx, .csv, .txt, .xls"
          class="input-file"
        />
        <p class="dropbox__label" v-if="isInitial">
          Drag your file(s) here to begin<br />
          or click to browse. (Excel, CSV, TXT)
        </p>
        <p v-if="isSaving || isExtract">We are analyzing your file...</p>
        <div
          v-if="isExtract"
          class="spinner-border text-primary"
          role="status"
        ></div>
        <CProgress
          v-if="isSaving"
          class="mb-3"
          style="width: 120px; margin: auto"
        >
          <CProgressBar :value="progress" />
        </CProgress>
      </div>
    </form>

    <div v-if="showInputs">
      <span class="filename">{{ this.filename }}:</span><br /><br />
      <div class="file-details">
        <div role="group" class="num-page">
          <label for="input-live">Name of the page:</label>
          <b-form-select v-model="page" :options="pages || []"></b-form-select>
        </div>
        <!-- <span>Name of the page:</span>
      <CSelect
        :value.sync="page"
        class="inputs"
        data-cy="input-page"
        :options="pages || ['']"
      ></CSelect> -->
        <!-- <span>№ of line with headers:</span>
      <CInput
        :value.sync="headers"
        style="width: 120px"
        class="inputs"
        data-cy="input-headers"
      ></CInput> -->
        <!-- <CFormInput
        type="text"
        id="no"
        label="№ of line with headers:"
      />-->
        <div role="group">
          <label for="input-live">№ of line with headers:</label>
          <b-form-input
            class="number-lines"
            v-model="headers"
            id="input-live"
            trim
          ></b-form-input>
        </div>
      </div>

      <CButton
        @click="extract"
        class="mazarsButton"
        :disabled="!/^[0-9]*$/.test(headers) || !headers"
        >Continue</CButton
      >
    </div>

    <!-- <div v-if="isSuccess">
        <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Upload again</a>
        </p>
        <ul class="list-unstyled">
          <li v-for="item in uploadedFiles">
            <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
          </li>
        </ul>
      </div> -->
    <!--FAILED-->
    <!-- <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div> -->
  </div>
</template>

<script>
import { ExcelService } from "@/services/excel";
import { TemplatesService } from "@/services/templates";
import { CSelect } from "@mz/coreui-vue";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3,
  STATUS_EXTRACT = 4;

export default {
  name: "FileUpload",
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "file",
      fileData: null,
      columns: null,
      pages: null,
      filename: "",
      progress: 0,
      showInputs: false,
    };
  },
  props: ["return_result", "reusedTemplate", "page", "headers"],
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isExtract() {
      return this.currentStatus === STATUS_EXTRACT;
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.fileData = null;
      this.columns = null;
      this.pages = null;
      this.filename = "";
      this.progress = 0;
      this.showInputs = false;
      this.$emit("get-data", {
        fileData: this.fileData,
        columns: this.columns,
        filename: this.filename,
        headers: this.headers,
        page: this.page,
      });
    },

    async send(formData) {
      var file = formData.getAll("file")[0];
      this.currentStatus = STATUS_SAVING;

      ExcelService.upload(file).then((res) => {
        // console.log(res);

        this.currentStatus = res.currentStatus;
        this.uploadedFiles = res.uploadedFiles;
        this.filename = res.filename;
        this.file_type = res.file_type;

        if (this.currentStatus == STATUS_SUCCESS) {
          if (res.pages.length) {
            this.pages = res.pages;
            this.page = this.pages[0];
            this.showInputs = true;
          } else {
            this.extract();
          }
        } else {
          this.$emit("get-errors", {
            errors: { errors: ["Error uploading file!"] },
          });
          return alert("File is wrong type or broken!");
        }
      });
    },

    async extract() {
      try {
        this.currentStatus = STATUS_EXTRACT;
        this.showInputs = false;

        if (!this.headers) {
          this.headers = 1;
        }
        if (!this.page) {
          this.page = "_";
        }

        var { data, res } = await ExcelService.extract({
          headers: this.headers,
          page: this.page,
          file_type: this.file_type,
        });
        console.log("res", res, data);
        if (res.status !== 200) {
          if (data.detail == "file_error") {
            alert("Unable to read file! Check that it is not empty or broken");
            this.$emit("get-errors", {
              errors: {
                errors: ["Can't process the file! Probably it's empty"],
              },
            });
          }
        }
        this.fileData = data.data;
        this.columns = data.columns;

        if (this.return_result) {
          this.$emit("get-data", {
            fileData: this.fileData,
            columns: this.columns,
            filename: this.filename,
            headers: this.headers,
            page: this.page,
          });
        } else {
          // this.$emit('get-data', { fileData: null})
          this.check_reuse();
        }
        this.currentStatus = STATUS_SUCCESS;
      } catch (err) {
        alert("Unable to read file! Check that it is not empty or broken");
        this.$emit("get-errors", {
          errors: { errors: ["Error uploading file!"] },
        });
      }
    },

    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // save it
      this.send(formData);
    },
    async check_reuse() {
      var { data, res } = await TemplatesService.check_reuse({
        templateData: this.reusedTemplate.columns,
        page: this.page ? this.page : "_",
        headers: this.headers,
      });
      if (res.status !== 200) {
        return alert("Error reading file, reload the page and try again");
      }
      if (data.success) {
        this.$emit("get-errors", { errors: data.data });
      }
    },
  },
  mounted() {
    this.reset();
    window.addEventListener("progress-changed", (event) => {
      this.progress = parseInt(
        (event.detail.loaded / event.detail.total) * 100
      );
    });
  },
  components: { CSelect },
};
</script>

<style lang="scss">
.file-details {
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #0c2044;
  }

  .num-page {
    flex: 1;
  }

  .custom-select,
  .number-lines {
    height: 40px;
    background: #f8f9fc;
    border: 1px solid #e7edfb;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #0c2044;
  }
}
.dropbox {
  // outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  color: dimgray;
  padding: 10px 10px;
  min-height: 110px; /* minimum height */
  position: relative;
  width: 100%;
  cursor: pointer;

  background: #f8f9fc;
  border: 2px dashed #eaecf0;
  border-radius: 10px;

  &__label {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #0c2044;
    margin-top: 25px;
  }
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 110px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #c6e3e6; /* when mouse over to the drop zone, change color */
}

// .dropbox p {
//   font-size: 1.2em;
//   text-align: center;
//   padding: 20px 0;
// }
.spinner-border {
  height: 3rem;
  width: 3rem;
  animation-duration: 2s;
  margin-left: calc(50% - 1.5rem);
  margin-bottom: 30px;
  position: relative;
}
.inputs {
  margin-bottom: 10px;
}
.filename {
  font-weight: 600;
  font-size: medium;
  position: relative;
}
</style>
